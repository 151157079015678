@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-layouts/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-kanban/styles/material.css";
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

*:not(.e-btn-icon, .e-icons) {
  font-family:'Spoqa Han Sans Neo', 'sans-serif'!important;
  font-size: 1rem;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact !important;
  /* Chrome, Safari 6 – 15.3, Edge */
}

html {
  font-family: Helvetica, -apple-system, "Segoe UI", "Helvetica Neue", sans-serif;
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  width: 90%;
  /* 스크롤바 숨김 - IE */
  -ms-overflow-style: none;
}

/* 스크롤바 숨김 chromium, firefox 기반*/
::-webkit-scrollbar {
  /* display: none; */
}

#root {
  height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@page {
  size: "A4";
  margin: 5mm;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.overlay-content {
  display: flex;
  width: 100%;
  left: 0;
  top: 0;
  align-items: center;
  height: 100%;
  justify-content: center;
  position: fixed;
  z-index: 999999;
  background: rgba(33, 33, 33, 0.7);
  flex-direction: column;
}

.overlay-content .wrapper {
  display: inherit;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overlay-content .wrapper .message {
  margin-top: 20px;
  color: white;
  font-weight: bold;
  text-shadow: 0 2px 5px black;
  font-size: large;
}

.e-input-group {
  margin-bottom: 0px !important;
}

.e-checkbox-wrapper .e-label {
  font-size: 11px !important;
}

.e-checkbox-wrapper.e-checkbox-disabled .e-frame.e-check {
  background-color: #e3165b !important;
  border-color: #e3165b !important;
}

.e-checkbox-wrapper.e-checkbox-disabled .e-label {
  color: black !important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: #EEEEEE !important;
}

.MuiMenuItem-root.Mui-selected:hover {
  color: #e3165b !important
}

#download_area {
  margin-top: 10px;
  font-size: 11px !important;
  width: 1000px !important;
  border: 1px solid black;
  padding: 8px;
}
