@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
.e-input-group .e-control-wrapper {
  margin-bottom: 0px !important;
  height: 10px !important;
}

.e-control .e-dropdownlist {
  background-color: bisque;
} 

.e-input-group-icon {
  margin: 0px 2px 0px 0px 
}
  
.wrapper-login-div {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.login-div {
  text-align: center;
  border: 1px solid #9e9e9e;
  border-radius: 15px;
  width: 400px;
  padding: 80px 50px 50px 50px;
}

.help-link {
  text-decoration: underline;
  color: blue;
  font-size: 14px;
}

/* CSS 파일 또는 스타일 시트에 추가 */
.e-kanban .e-kanban-table .e-header-cells[data-key='0'] {
  background-color: #ffcdd2
}

.e-kanban .e-kanban-table .e-header-cells[data-key='1'] {
  background-color: #ffe082
}

.e-grid .e-toolbar-items {
  background: #f8f8f8 !important;
}

.e-grid .e-headercell {
  cursor: pointer;
  background: #f8f8f8 !important;
}

.e-grid .e-headertext {
  font-size: 12px !important;
  white-space: normal;
}

.e-grid .e-headercell .e-headercelldiv {
  white-space: normal;
  height: auto
}

.e-grid .e-gridheader {
  padding-right: 0px !important;
}

.e-grid .e-content {
  overflow-y: hidden !important;
  min-height: 200px;
}

.e-grid .e-row .e-rowcell {
  pointer-events: none;
}

.eventReport .e-row .e-rowcell {
  pointer-events: none;
  padding-top: 24px;
  padding-bottom: 24px;
}

.e-grid .e-rowcell {
  white-space: normal;
}

.e-grid .e-row .e-templatecell {
  pointer-events: auto;
}

.e-grid .e-row .e-templatecell:hover {
  background-color: inherit;
}

.e-grid .e-row:hover .e-rowcell {
  background-color: transparent !important;
}

.e-grid .e-row[aria-selected="true"] .e-rowcell {
  background-color: transparent !important;
}

.crmSearch .e-columnheader .e-headercell {
  padding: 0px;
}

.crmSearch .e-gridheader tr th:first-child {
  padding-left: 0px !important;}

.crmSearch .e-gridheader tr th:last-child {
  padding-right: 0px !important;
}

.crmSearch .e-rowcell:first-child,
.crmSearch .e-summarycell:first-child {
  padding-left: 0px !important;
}

.crmSearch .e-rowcell:last-child,
.crmSearch .e-summarycell:last-child {
  padding-right: 0px !important;
}

.crmSearch .e-row .e-rowcell {
  padding: 0px;
  white-space: normal !important;
}
*:not(.e-btn-icon, .e-icons) {
  font-family:'Spoqa Han Sans Neo', 'sans-serif'!important;
  font-size: 1rem;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact !important;
  /* Chrome, Safari 6 – 15.3, Edge */
}

html {
  font-family: Helvetica, -apple-system, "Segoe UI", "Helvetica Neue", sans-serif;
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  width: 90%;
  /* 스크롤바 숨김 - IE */
  -ms-overflow-style: none;
}

/* 스크롤바 숨김 chromium, firefox 기반*/
::-webkit-scrollbar {
  /* display: none; */
}

#root {
  height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

@page {
  size: "A4";
  margin: 5mm;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.overlay-content {
  display: flex;
  width: 100%;
  left: 0;
  top: 0;
  align-items: center;
  height: 100%;
  justify-content: center;
  position: fixed;
  z-index: 999999;
  background: rgba(33, 33, 33, 0.7);
  flex-direction: column;
}

.overlay-content .wrapper {
  display: inherit;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overlay-content .wrapper .message {
  margin-top: 20px;
  color: white;
  font-weight: bold;
  text-shadow: 0 2px 5px black;
  font-size: large;
}

.e-input-group {
  margin-bottom: 0px !important;
}

.e-checkbox-wrapper .e-label {
  font-size: 11px !important;
}

.e-checkbox-wrapper.e-checkbox-disabled .e-frame.e-check {
  background-color: #e3165b !important;
  border-color: #e3165b !important;
}

.e-checkbox-wrapper.e-checkbox-disabled .e-label {
  color: black !important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: #EEEEEE !important;
}

.MuiMenuItem-root.Mui-selected:hover {
  color: #e3165b !important
}

#download_area {
  margin-top: 10px;
  font-size: 11px !important;
  width: 1000px !important;
  border: 1px solid black;
  padding: 8px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

