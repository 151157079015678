.e-grid .e-toolbar-items {
  background: #f8f8f8 !important;
}

.e-grid .e-headercell {
  cursor: pointer;
  background: #f8f8f8 !important;
}

.e-grid .e-headertext {
  font-size: 12px !important;
  white-space: normal;
}

.e-grid .e-headercell .e-headercelldiv {
  white-space: normal;
  height: auto
}

.e-grid .e-gridheader {
  padding-right: 0px !important;
}

.e-grid .e-content {
  overflow-y: hidden !important;
  min-height: 200px;
}

.e-grid .e-row .e-rowcell {
  pointer-events: none;
}

.eventReport .e-row .e-rowcell {
  pointer-events: none;
  padding-top: 24px;
  padding-bottom: 24px;
}

.e-grid .e-rowcell {
  white-space: normal;
}

.e-grid .e-row .e-templatecell {
  pointer-events: auto;
}

.e-grid .e-row .e-templatecell:hover {
  background-color: inherit;
}

.e-grid .e-row:hover .e-rowcell {
  background-color: transparent !important;
}

.e-grid .e-row[aria-selected="true"] .e-rowcell {
  background-color: transparent !important;
}

.crmSearch .e-columnheader .e-headercell {
  padding: 0px;
}

.crmSearch .e-gridheader tr th:first-child {
  padding-left: 0px !important;}

.crmSearch .e-gridheader tr th:last-child {
  padding-right: 0px !important;
}

.crmSearch .e-rowcell:first-child,
.crmSearch .e-summarycell:first-child {
  padding-left: 0px !important;
}

.crmSearch .e-rowcell:last-child,
.crmSearch .e-summarycell:last-child {
  padding-right: 0px !important;
}

.crmSearch .e-row .e-rowcell {
  padding: 0px;
  white-space: normal !important;
}