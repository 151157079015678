.e-input-group .e-control-wrapper {
  margin-bottom: 0px !important;
  height: 10px !important;
}

.e-control .e-dropdownlist {
  background-color: bisque;
} 

.e-input-group-icon {
  margin: 0px 2px 0px 0px 
}
  